var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"main-title"},[_vm._v(_vm._s(_vm.TITLE))]),_c('total',{attrs:{"total":_vm.COUNT}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"lg":"4","md":"6","sm":"6","cols":"12"}},[_c('v-btn',{staticClass:"mr-2 btn__task",class:{ active: !_vm.inProgress },attrs:{"width":"100%"},on:{"click":function($event){return _vm.getFilterData(
            _vm.objectProfile,
            _vm.objectVehicle,
            _vm.sortOptions,
            _vm.page,
            false
          )}}},[_vm._v(_vm._s(_vm.$t("buttons.inProgress")))])],1),_c('v-col',{attrs:{"lg":"4","md":"6","sm":"6","cols":"12"}},[_c('v-btn',{staticClass:"mr-2 btn__task",class:{ active: _vm.inProgress },attrs:{"width":"100%"},on:{"click":function($event){return _vm.getFilterData(_vm.objectProfile, _vm.objectVehicle, _vm.sortOptions, _vm.page, true)}}},[_vm._v(_vm._s(_vm.$t("settings.faire")))])],1)],1),_c('table-viewing',{attrs:{"headers":_vm.headers,"users_data":_vm.TASKFIELDS,"page":_vm.page,"load":_vm.load},on:{"onPagination":function($event){
          var i = arguments.length, argsArray = Array(i);
          while ( i-- ) argsArray[i] = arguments[i];
return _vm.getFilterData.apply(void 0, [ _vm.objectProfile, _vm.objectVehicle, _vm.sortOptions ].concat( argsArray ))},"sortBy":function($event){
          var i = arguments.length, argsArray = Array(i);
          while ( i-- ) argsArray[i] = arguments[i];
return _vm.getFilterData.apply(void 0, [ _vm.objectProfile, _vm.objectVehicle ].concat( argsArray, [_vm.page] ))}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }