<template>
  <div class="home">
    <v-row>
      <v-col cols="12">
        <h2 class="main-title">{{ TITLE }}</h2>
        <total :total="COUNT" />
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="4" md="6" sm="6" cols="12">
        <v-btn
          :class="{ active: !inProgress }"
          class="mr-2 btn__task"
          width="100%"
          @click="
            getFilterData(
              objectProfile,
              objectVehicle,
              sortOptions,
              page,
              false
            )
          "
          >{{ $t("buttons.inProgress") }}</v-btn
        >
      </v-col>
      <v-col lg="4" md="6" sm="6" cols="12">
        <v-btn
          width="100%"
          class="mr-2 btn__task"
          :class="{ active: inProgress }"
          @click="
            getFilterData(objectProfile, objectVehicle, sortOptions, page, true)
          "
          >{{ $t("settings.faire") }}</v-btn
        >
      </v-col>
    </v-row>

    <table-viewing
      :headers="headers"
      :users_data="TASKFIELDS"
      @onPagination="
        getFilterData(objectProfile, objectVehicle, sortOptions, ...arguments)
      "
      @sortBy="getFilterData(objectProfile, objectVehicle, ...arguments, page)"
      :page="page"
      :load="load"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import total from "@/components/Total.vue";
import tableViewing from "@/components/TableViewing";

export default {
  name: "task",
  components: {
    total,
    tableViewing,
  },
  data: (vm) => {
    return {
      page: 1,
      objectProfile: undefined,
      objectVehicle: undefined,
      sortOptions: undefined,
      inProgress: false,
      load: true,
      headers: [
        { text: vm.$t("settings.id"), value: "id", visible: true },
        {
          text: vm.$t("table.creationTime"),
          value: "created_dt",
          visible: true,
        },
        { text: vm.$t("table.weightbox"), value: "weightbox", visible: true },
        { text: vm.$t("table.vehicle"), value: "vehicle", visible: true },
        { text: vm.$t("table.cargo"), value: "cargo", visible: true },
        { text: "actions", value: "actions", sortable: false, visible: true },
      ],
    };
  },
  props: {
    users_data: {},
    title: {},
  },
  computed: {
    ...mapGetters(["TASKFIELDS", "TITLE", "COUNT"]),
  },

  methods: {
    getFilterData(
      profile = this.objectProfile,
      vehicle = this.objectVehicle,
      sort = this.sort,
      page = this.page,
      inProgress = this.inProgress
    ) {
      this.objectProfile = profile;
      this.objectVehicle = vehicle;
      this.sortOptions = sort;
      this.page = page;
      this.inProgress = inProgress;

      let desc =
        this.sortOptions.desc.length > 0 && this.sortOptions.desc[0] ? "" : "-";
      let arg = "?";
      this.page ? (arg = arg + `page=${this.page}`) : "";
      this.objectProfile
        ? (arg = arg + `&driver=${this.objectProfile.id}`)
        : "";
      this.objectVehicle
        ? (arg = arg + `&vehicle=${this.objectVehicle.id}`)
        : "";
      this.sortOptions.ordering_field != ""
        ? (arg =
            arg +
            `${
              this.objectProfile || this.objectVehicle || this.page ? "&" : ""
            }ordering=${desc}${this.sortOptions.ordering_field}`)
        : "";
      this.inProgress !== undefined
        ? (arg = arg + `&finished=${inProgress}`)
        : "";

      this.getTaskList({ book: "taskDrivers", args: arg });
      this.load = false;
    },
    ...mapActions(["getTaskList"]),
  },
};
</script>

<style lang="scss" >
</style>